import { Image, Button, ActionSheet, Tag, NavBar, Icon, AddressEdit, Field, Popup, Area, Toast } from 'vant';
import apis from '../utils/apis'
import { areaList } from '@vant/area-data';
import couponList from "../../../components/couponList";

export default {
  name: 'goodsOrder',
  components: {
    couponList,
    'van-image': Image,
    'van-button': Button,
    'van-action-sheet': ActionSheet,
    'van-tag': Tag,
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-address-edit': AddressEdit,
    'van-field': Field,
    'van-popup': Popup,
    'van-area': Area,
  },
  data () {
    return {
      goodsInfo: {},
      buyInfo: {
        consignee: '', // 收货人
        consigneePhone: '', // 收货人电话
        consigneeArea: '', //收货所在地区
        consigneeAddr: '', //收货详细地址
        orderItems: [],
      },
      orderShow: false, // 显示弹窗订单明细
      showArea: false, // 显示地区选择弹窗
      areaList,
      pattern: /\d{6}/,
      showMessage:false,
      showCouponList:false,//是否展示优惠券列表
    }
  },
  methods: {
    chooseCoupon(row){
      this.goodsInfo.userCoupon = {...row};
      this.goodsInfo.couponCode = this.goodsInfo.userCoupon.couponCode; //消费劵编码
      this.goodsInfo.couponPrice = this.goodsInfo.totalPrice - this.goodsInfo.userCoupon.couponAmount; //劵后总价
      this.showCouponList = false;
    },
    queryCouponListByCommditysId(){
      let me = this;
      me.$post({
        url:apis.queryCouponListByCommditysId,
        params: {
          'bookUseDate':me.$formatDate(new Date(),"yyyy-MM-dd"),
          'token': me.$global.token,
          "commodityId": me.goodsInfo.commodityId,
        }
      }).then(res => {
        this.couponArr = res.data;
      });
    },
    /** 创建订单 */
    create () {
      this.buyInfo.token = this.$global.token
      this.buyInfo.source = 3 // 来源，1：智游乐山APP、2：第三方、3：微信公众号
      let obj = {
        "commodityId": this.goodsInfo.commodityId, //	商品ID
        "discountPoints": 0,
        "quantity": this.goodsInfo.num, // 数量
      }
      this.buyInfo.orderItems = []
      this.buyInfo.orderItems.push(obj)
      this.buyInfo.couponCode = this.goodsInfo.couponCode ? this.goodsInfo.couponCode : ''

      if (this.validator(this.buyInfo)) {
        this.$post2({
          url: apis.create,
          params: this.buyInfo,
        }).then(res => {
          this.goPay(res.data)

        }).catch(err => {
          Toast(err.message);

        });
      }
    },
    // 获取酒店info done
    getInfo () {
      this.infoParam.token = this.$global.token;
      this.$get({
        url: apis.findInfo,
        params: this.infoParam
      }).then(rsp => {
        this.hotelInfo = rsp;

      });
    },
    /**跳转提交订单页面 */
    goPay (row) {
      this.$router.push({
          path: '/goodsPay',
          name: 'goodsPay',
          query: {
            orderId: row.order.orderId,
          }
        }
      )
    },
    /**地区选择 */
    onConfirm (values) {
      this.buyInfo.consigneeArea = values.filter((item) => !!item).map((item) => item.name).join('/');
      this.showArea = false;
    },
    /** 验证 */
    validator (val) {
      let reg = /^[1][3456789]\d{9}$/
      console.log(val)
      if (val.consignee.replace(/\s/g, '') == '') {
        Toast('收货人不能为空，请填写收货人~');
        return false
      }
      if (val.consigneePhone.replace(/\s/g, '') == '') {
        Toast('收货人电话不能为空，请填写收货人电话~');
        return false
      }
      if (!reg.test(val.consigneePhone)) {
        Toast('收货人电话格式不正确~');
        return false
      }
      if (val.consigneeArea == '') {
        Toast('收货所在地区不能为空，请选择收货所在地区~');
        return false
      }
      if (val.consigneeAddr == '') {
        Toast('收货详细地址不能为空，请填写收货详细地址~');
        return false
      }
      if (val.orderItems.lengt <= 0) {
        Toast('未获取商品信息，请返回上一级重新选择商品~');
        return false
      }

      return true
    },
    /** 显示弹窗商品详情 */
    openOrderPop () {
      this.orderShow = true
    },
    couponUse(){
      this.$get({
        url: apis.findByColumnCode,
        params: {
          columnCode: 'bxjyhgz',
          token: this.$global.token,
        },
      }).then(rsp => {
        this.couponList = rsp;
        this.showMessage = true
      });
    },
    back () {
      this.$router.go(-1)
    },
  },
  created () {
    this.goodsInfo = JSON.parse(sessionStorage.getItem('buyInfo'))
    this.goodsInfo.totalPrice = this.goodsInfo.sellPrice * this.goodsInfo.num //实付总价

    if (this.goodsInfo.userCoupon) {
      this.goodsInfo.couponCode = this.goodsInfo.userCoupon.couponCode //消费劵编码
      this.goodsInfo.couponPrice = this.goodsInfo.totalPrice - this.goodsInfo.userCoupon.couponAmount //劵后总价
    }
    this.queryCouponListByCommditysId();
    console.log(this.goodsInfo)
  },
  mounted () {
  },
}
