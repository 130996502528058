<template>
  <div class="container">
    <!-- 顶部 -->
    <van-nav-bar title="填写订单" @click-left="back()">
      <template #left>
        <van-icon color="#333333" :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="main">
      <!-- 用户信息 -->
      <div class="user">
        <h2>填写收货地址</h2>
        <van-field v-model="buyInfo.consignee" label="收货人" name="收货人" placeholder="请填写收货人姓名" :rules="[{ pattern, message: '请输入正确内容' }]" />
        <van-field v-model="buyInfo.consigneePhone" label="手机号码" name="手机号码" placeholder="请填写收货人手机号" maxlength="11" :rules="[{ validator, message: '请输入正确内容' }]" />
        <van-field readonly clickable name="所在地区" v-model="buyInfo.consigneeArea" label="地区选择" placeholder="点击选择省市区" @click="showArea = true" />
        <van-field v-model="buyInfo.consigneeAddr" rows="1" autosize label="详细地址" type="textarea" placeholder="街道、楼牌号" />
      </div>
      <!-- 商品信息 -->
      <div class="goods">
        <h2>商品信息</h2>
        <div class="row">
          <van-image :src="goodsInfo.cover" width="120" height="120" class="goods_img" fit="cover">
            <template v-slot:error>加载失败</template>
          </van-image>
          <div class="goods_info">
            <h4>{{goodsInfo.name}}</h4>
            <p>
              <span><sub>￥</sub>{{goodsInfo.sellPrice?goodsInfo.sellPrice/100:0}}</span>
              <span>数量 x {{goodsInfo.num}}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 消费劵详情 -->
      <div class="coupon" v-if='goodsInfo.userCoupon'>
        <h2>使用消费劵 <em @click="couponUse()">《消费劵使用规则》</em></h2>
        <div class="cpo row">
          <span class="lable">消费劵</span>
          <span class="name">{{goodsInfo.userCoupon.couponName}}</span>
          <span class="money">￥{{goodsInfo.userCoupon.couponAmount?goodsInfo.userCoupon.couponAmount/100:0}}</span>
          <img src="../imgs/coupon_right.png" alt="" class="cou_choose" @click="showCouponList = true">
        </div>
      </div>
      <!--<div class="coupon">
        <h2>使用消费劵 <em @click="couponUse()">《消费劵使用规则》</em></h2>
        <div class="cpo row">
          <span class="lable">消费劵</span>
          <span class="name">111</span>
          <span class="money">￥111</span>
          <img src="../imgs/coupon_right.png" alt="" class="cou_choose" @click="showCouponList = true">

        </div>
      </div>-->
      <div class="btotp"></div>
      <!-- 底部 -->
      <div class="settlement">
        <template v-if="goodsInfo.postCouponPrice">
        <h2>劵后价</h2>
        <div class="price" v-if="goodsInfo.postCouponPrice">￥{{goodsInfo.couponPrice?goodsInfo.couponPrice/100:0}}</div>
      </template>

        <div class="evoprice">
          <span>原价 ￥{{goodsInfo.totalPrice?goodsInfo.totalPrice/100:0}}</span>
          <span @click="openOrderPop()">明细
            <van-icon name="arrow-up" />
          </span>
        </div>
        <van-button class="btn" round color="linear-gradient(to right, #92E460, #3BD367)" @click="create()">
          立即支付
        </van-button>
      </div>
      <!-- 地区选择弹窗 -->
      <van-popup v-model:show="showArea" position="bottom">
        <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" :columns-placeholder="['请选择', '请选择', '请选择']" />
      </van-popup>
      <!-- 弹窗订单明细 -->
      <van-action-sheet title="订单明细" v-model:show="orderShow" class="infoPop">
        <div class="content">
          <div class="box">
            <div class="goods_info">
              <span class="name">{{goodsInfo.name}}</span>
              <span class="num">x{{goodsInfo.num}}</span>
              <div class="price">￥{{goodsInfo.sellPrice?goodsInfo.sellPrice/100:0}}</div>
            </div>
            <div class="tag" v-if='goodsInfo.userCoupon'>
              <van-tag round plain color="#FF3E6E" text-color="#FF3E6E">消费劵 抵扣{{goodsInfo.userCoupon.couponAmount?goodsInfo.userCoupon.couponAmount/100:0}}</van-tag>
            </div>
          </div>
          <div class="buy">
            <span>实付金额</span>
            <span>￥{{goodsInfo.couponPrice?goodsInfo.couponPrice/100:0}}</span>
          </div>
        </div>

        <div class="settlement">
          <template v-if="goodsInfo.postCouponPrice">
            <h2>劵后价</h2>
          <div class="price">￥{{goodsInfo.couponPrice?goodsInfo.couponPrice/100:0}}</div>
          </template>

          <div class="evoprice">
            <span>原价 ￥{{goodsInfo.totalPrice?goodsInfo.totalPrice/100:0}}</span>
            <span>明细
              <van-icon name="arrow-up" />
            </span>
          </div>
          <van-button class="btn" round color="linear-gradient(to right, #92E460, #3BD367)" @click="create()">
            立即支付
          </van-button>
        </div>
      </van-action-sheet>
      <!-- 优惠规则 -->
      <van-popup round v-model:show="showMessage"  class="message">
      <div class="pop">
        <div class="tt">优惠规则</div>
        <div class="content" v-html="couponList.content"></div>
        <div class="close" @click="showMessage = false">关闭</div>
      </div>
    </van-popup>
    </div>


    <!--优惠券列表弹窗-->
    <couponList @chooseCoupon="chooseCoupon" @close="showCouponList = false" :couponArr="couponArr" v-if="showCouponList"></couponList>

  </div>

</template>
<script src='./goodsOrder.js'></script>
<style lang="scss" scoped src='./goodsOrder.scss'></style>
